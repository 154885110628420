<template>
  <div>
    <div class="page-title dashboard">
      <div class="container">
          <div class="col-6">
        <div class="row pb-3">
            <div class="page-title-content">
              <p>
                Welcome Back,
                <!-- <span class="text-success"> {{ name }}</span> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="card sub-menu">
              <div class="card-body">
                <ul class="d-flex">
                  <li
                    v-for="link in links"
                    :key="link.to"
                    class="nav-item"
                    :class="$route.name === link.title ? 'active' : null"
                  >
                    <router-link :to="link.to" class="nav-link">
                      <i :class="link.icon"></i>
                      <span>{{ link.text }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="row">

              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <h1 class="card-title">Personal Information</h1>
                  </div>
                  <div class="card-body">
                    <form method="post" name="myform" class="personal_validate">
                      <div class="form-row">
                        <div class="form-group col-xl-5 col-md-4">
                          <label class="mr-sm-2">First Name</label>
                          <input
                            type="text"
                            class="form-control"
                            name="fullname"
                            v-model="$v.first.$model"
                          />
                          <div v-if="$v.first.$error" class="error">Please enter your firstname</div>
                        </div>

                        <div class="form-group col-xl-2 col-md-4">
                          <label class="mr-sm-2">Middle Name</label>
                          <input
                            type="text"
                            class="form-control"
                            name="middle"
                            v-model="mi"
                          />
                        </div>

                        <div class="form-group col-xl-5 col-md-4">
                          <label class="mr-sm-2">Last Name</label>
                          <input
                            type="text"
                            class="form-control"
                            name="fullname"
                            v-model="$v.last.$model"
                          />
                          <div v-if="$v.first.$error" class="error">Please type in your lastname</div>
                        </div>
                      </div>
                      
                      <div class="form-row">
                      <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">Gender</label>
                          <select class="form-control" name="country" v-model="$v.gender.$model">
                            <option value="">Select</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="Other/No Response">
                              Other/No Response
                            </option>
                          </select>
                          <div v-if="$v.gender.$error" class="error">Please type in your gender</div>
                        </div>
                      </div>

                      <br><h4>Residence Address:</h4><br>
                      <div class="row">
                        <div class="form-group col-xl-8 col-md-8">
                          <label class="mr-sm-2">Current Address</label>
                          <input
                            placeholder="Enter your address"
                            class="form-control"
                            name="add1"
                            type="text"
                            v-model="$v.addr1.$model"
                          />
                          <div v-if="$v.addr1.$error" class="error">Please type in your address</div>
                        </div>

                        <div class="form-group col-xl-4 col-md-4">
                          <label class="mr-sm-2">Suite/Apt/Rm No</label>
                          <input
                            placeholder=""
                            class="form-control"
                            name="addr2"
                            type="text"
                            v-model="addr2"
                          />
                        </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-4">
                              <div class="form-group label-floating" >
                                <label class="control-label mr-sm-2">City</label><br />
                                <input name="city" id="city" type="text" class="form-control" v-model="$v.city.$model" required>
                                <div v-if="$v.city.$error" class="error">Please type in your city</div>
                              </div>
                          </div>
                            
                          <div class="col-sm-4">
													<div class="form-group label-floating" >
                              <label class="control-label mr-sm-2">State</label><br/>
                              <input name="state" id="state" type="text" class="form-control" v-model="$v.state.$model" required>
                              <div v-if="$v.state.$error" class="error">Please type in your state</div>
                          </div>
                          </div>

                          <div class="col-sm-4">
													<div class="form-group label-floating" >
                              <label class="control-label mr-sm-2">Zip Code</label><br/>
                              <input name="zip" type="number" class="form-control" v-model="$v.zip.$model" required>
                              <div v-if="$v.zip.$error" class="error">Zip cannot be empty</div>
                          </div>
                          </div>
                      </div>

                      <div class="form-row">
                          <div class="col-sm-4">
                            <h4>Length of time at this address</h4>
                          </div>
                          <div class="col-sm-3">
													  <div class="form-group label-floating" >
                              <label class="control-label mr-sm-2">Months</label><br/>
                                <input name="since" type="number" class="form-control" v-model="$v.since.$model">
                                <div v-if="$v.since.$error" class="error">Months cannot be empty</div>
                            </div>
                          </div>

                          <div class="col-sm-3">
													  <div class="form-group label-floating" >
                              <label class="control-label mr-sm-2">Years</label><br/>
                                <input name="since" type="number" class="form-control">
                            </div>
                          </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">Date of Birth</label>
                          <input
                            type="date"
                            class="form-control"
                            name="dob"
                            v-model="$v.dob.$model"
                          />
                          <div v-if="$v.dob.$error" class="error">Please select a DOB</div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-xl-4 col-md-6">
                          <label class="mr-sm-2">Are you a US Resident?</label>
                          <select class="form-control" name="usResident" v-model="$v.usResident.$model">
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <div v-if="$v.usResident.$error" class="error">Please select an option</div>
                        </div>
                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">Are you a US Citizen?</label>
                          <select class="form-control" name="citenzenship" v-model="$v.citizenship.$model">
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <div v-if="$v.citizenship.$error" class="error">Please select an option</div>
                        </div>
                        <div class="form-group col-xl-5 col-md-6">
                          <label class="mr-sm-2"
                            >Will you be doing transactions for someone
                            else?</label
                          >
                          <select class="form-control" name="txForOthers" v-model="$v.txForOthers.$model">
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <div v-if="$v.txForOthers.$error" class="error">Please select an option</div>
                        </div>
                      </div>


                      <h4><br>Employment Information:<br><br></h4>

                      <div class="form-row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">Employment Status</label>
                          <select class="form-control" name="employmentStatus" v-model="$v.employmentStatus.$model">
                            <option value="">Select</option>
                            <option value="Employed">Employed</option>
                            <option value="Self Employed">Self Employed</option>
                            <option value="Unemployed">Unemployed</option>
                            <option value="Retired">Retired</option>
                            <option value="Student">Student</option>
                          </select>
                          <div v-if="$v.employmentStatus.$error" class="error">Please select an option</div>
                        </div>

                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2"
                            >Title (if retired - prior title)</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            name="jobTitle"
                            v-model="$v.jobTitle.$model"
                          />
                          <div v-if="$v.jobTitle.$error" class="error">Job title cannot be empty</div>
                        </div>

                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2"
                            >Name of Employer</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            name="employer"
                            v-model="$v.employer.$model"
                          />
                          <div v-if="$v.employer.$error" class="error">Employer cannot be empty</div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">Address</label>
                          <input
                            id="autocomplete"
                            placeholder="Enter your address"
                            class="form-control"
                            onFocus="geolocate()"
                            name="employmentAddress"
                            type="text"
                            v-model="$v.employerAddr1.$model"
                          />
                          <div v-if="$v.employerAddr1.$error" class="error">Please type in an address</div>
                        </div>

                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">Suite/Apt/Rm No</label>
                          <input
                            type="text"
                            class="form-control"
                            name="employmentSuite"
                            v-model="employerAddr2"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-xl-4 col-md-6">
                          <label class="mr-sm-2">City</label>
                          <input
                            id="autocomplete"
                            class="form-control"
                            onFocus="geolocate()"
                            name="employerCity"
                            type="text"
                            v-model="$v.employerCity.$model"
                          />
                          <div v-if="$v.employerCity.$error" class="error">Please type in a city</div>
                        </div>

                        <div class="form-group col-xl-4 col-md-6">
                          <label class="mr-sm-2">State</label>
                          <input
                            type="text"
                            class="form-control"
                            name="employerState"
                            v-model="$v.employerState.$model"
                          />
                          <div v-if="$v.employerState.$error" class="error">Please type in a state</div>
                        </div>

                        <div class="form-group col-xl-4 col-md-6">
                          <label class="mr-sm-2">Zipcode</label>
                          <input
                            type="number"
                            class="form-control"
                            name="employerZip"
                            maxlength="5"
                            v-model="$v.employerZip.$model"
                          />
                          <div v-if="$v.employerZip.$error" class="error">Please type in a zip code</div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2"
                            >Expected Annual Income (This Year) USD $</label
                          >
                          <input
                            type="number"
                            class="form-control"
                            name="income"
                            v-model="$v.income.$model"
                          />
                          <div v-if="$v.income.$error" class="error">Please type in an income</div>
                        </div>

                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2"
                            >Do you have a Bank Account?</label
                          >
                          <select class="form-control" name="hasBankAccount" v-model="$v.hasBankAccount.$model">
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <div v-if="$v.hasBankAccount.$error" class="error">Please select an option</div>
                        </div>
                        
                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2"
                            >Intended Use/Purpose of Transactions?</label
                          >
                          <select class="form-control" name="intendUseDetail" v-model="$v.intendUseDetail.$model">
                            <option value="">Select</option>
                            <option value="Investment">Investment</option>
                            <option value="Trading">Trading</option>
                            <option value="Business">Business</option>
                            <option value="Privacy">Privacy</option>
                            <option value="Online Purchasing">
                              Online Purchasing
                            </option>
                            <option value="Online Payment">
                              Online Payment
                            </option>
                            <option value="Other">Other</option>
                          </select>
                          <div v-if="$v.intendUseDetail.$error" class="error">Please select an option</div>
                        </div>
                      
                      <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2"
                            >Source of Funds?</label
                          >
                          <select class="form-control" name="intendedUse" v-model="$v.fundSource.$model">
                            <option value="">Select</option>
                            <option value="Investments"> Investments </option>
	                          <option value="Inheritance"> Inheritance </option>
	                          <option value="Occupation"> Occupation </option>
	                          <option value="Insurance Settlement"> Insurance Settlement</option>
	                          <option value="Sale of Assets"> Sale of assets </option>
	                          <option value="Other"> Other</option>
                          </select>
                          <div v-if="$v.fundSource.$error" class="error">Please select an option</div>
                        </div>  
                      </div>


                      <br><h4>Contact Information:</h4><br>

                      <div class="form-row">
                        <div class="form-group col-xl-3 col-md-3">
                          <label class="mr-sm-2"
                            >Choose an applicable option</label
                          >
                          <select class="form-control" name="phoneType" v-model="$v.phoneType.$model">
                            <option value="">Phone Type</option>
                            <option value="Mobile/Cell">
                              Mobile/Cell
                            </option>
                            <option value="Home">
                              Home
                            </option>
                            <option value="Work">
                              Work
                            </option>
                          </select>
                          <div v-if="$v.phoneType.$error" class="error">Please select an option</div>
                        </div>
                        <div class="form-group col-xl-4 col-md-6">
                          <label class="mr-sm-2">Phone Number</label>
                          <input
                            type="text"
                            pattern="\d{3}"
                            class="form-control"
                            name="phone"
                            maxlength="11"
                            v-model="$v.phone.$model"
                          />
                          <div v-if="$v.phone.$error" class="error">Please type in your phone number</div>
                        </div>

                        <div class="form-group col-xl-4 col-md-6">
                          <label class="mr-sm-2">Email Address</label>
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            v-model="$v.email.$model"
                          />
                          <div v-if="$v.email.$error" class="error">Please type in a valid email address</div>
                        </div>
                      </div>

                      <h4><br>Identification Information:<br><br></h4>
                     
                      <div class="form-row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2"
                            >Choose an applicable option</label
                          >
                          <select class="form-control" name="idType" v-model="$v.idType.$model">
                            <option value="">Select</option>
                            <option value="Drivers License">
                              Drivers License
                            </option>
                            <option value="Passport Number">
                              Passport Number
                            </option>
                          </select>
                          <div v-if="$v.idType.$error" class="error">Please select an option</div>
                        </div>
                      </div>

                      <div class="form-row" v-if="idType == 'Drivers License'">
                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">State</label>
                          <input
                            type="text"
                            class="form-control"
                            name="state2"
                            v-model="$v.state2.$model"
                          />
                          <div v-if="$v.state.$error" class="error">Please type in a state</div>
                        </div>

                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">ID No</label>
                          <input
                            type="text"
                            class="form-control"
                            name="idNumber"
                            v-model="$v.idNumber.$model"
                          />
                          <div v-if="$v.idNumber.$error" class="error">Please type in your ID number</div>
                        </div>

                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">Date Issued</label>
                          <input
                            type="date"
                            class="form-control"
                            name="employmentTitle"
                            v-model="$v.issued.$model"
                          />
                          <div v-if="$v.issued.$error" class="error">Please select an issued date</div>
                        </div>

                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">Expiration Date</label>
                          <input
                            type="date"
                            class="form-control"
                            name="employmentTitle"
                            v-model="$v.expires.$model"
                          />
                          <div v-if="$v.expires.$error" class="error">Please select an expiry date</div>
                        </div>
                      </div>

                      <div v-if="idType == 'Passport Number'" class="form-row">
                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">Passport No</label>
                          <input
                            type="text"
                            class="form-control"
                            name="employmentTitle"
                            v-model="$v.idNumber.$model"
                          />
                          <div v-if="$v.idNumber.$error" class="error">Please type in an ID number</div>
                        </div>

                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">Date Issued</label>
                          <input
                            type="date"
                            class="form-control"
                            name="employmentTitle"
                            v-model="$v.issued.$model"
                          />
                          <div v-if="$v.issued.$error" class="error">Please select an issued date</div>
                        </div>

                        <div class="form-group col-xl-3 col-md-6">
                          <label class="mr-sm-2">Expiration Date</label>
                          <input
                            type="date"
                            class="form-control"
                            name="employmentTitle"
                            v-model="$v.expires.$model"
                          />
                          <div v-if="$v.expires.$error" class="error">Please select an expiry date</div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2"
                            >Choose an applicable option</label
                          >
                          <select class="form-control">
                            <option value="">Select</option>
                            <option value="SSN">SSN/ITIN</option>
                          </select>
                        </div>

                        <div class="form-group col-xl-2 col-md-6">
                          <label class="mr-sm-2">XXX</label>
                          <input
                            type="text"
                            pattern="\d*"
                            onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                            class="form-control"
                            name="ssn1"
                            v-model="$v.ssn1.$model"
                            maxlength="3"
                          />
                          <div v-if="$v.ssn1.$error" class="error">This field can not be empty</div>
                        </div>

                        <div class="form-group col-xl-2 col-md-6">
                          <label class="mr-sm-2">XX</label>
                          <input
                            type="text"
                            pattern="\d*"
                            onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                            class="form-control"
                            name="ssn2"
                            v-model="$v.ssn2.$model"
                            maxlength="2"
                          />
                          <div v-if="$v.ssn2.$error" class="error">This field can not be empty</div>
                        </div>

                        <div class="form-group col-xl-2 col-md-6">
                          <label class="mr-sm-2">XXXX</label>
                          <input
                            type="text"
                            pattern="\d*"
                            class="form-control"
                            name="ssn3"
                            onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                            v-model="$v.ssn3.$model"
                            maxlength="4"
                          />
                          <div v-if="$v.ssn3.$error" class="error">This field can not be empty</div>
                        </div>
                      </div>

                      <h4><br>Additional Information:<br><br></h4>
                      <div class="form-row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">Mother's Maiden Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="$v.maidenName.$model"
                          />
                          <div v-if="$v.maidenName.$error" class="error">This field can not be empty</div>
                        </div>
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2">First School Attended</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="$v.firstSchool.$model"
                          />
                          <div v-if="$v.firstSchool.$error" class="error">This field can not be empty</div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-xl-6 col-md-6">
                          <label class="mr-sm-2"
                            >Please choose the amount(s) of your expected transaction(s) on a monthly basis.</label
                          >
                          <select class="form-control" name="intendedUse" v-model="$v.applicationTier.$model">
                            <option value="">Select</option>
                            <option value="3">Up to $50,000</option>
                            <option value="4">Up to $100,000</option>
                            <option value="5">Up to $250,000+</option>
                          </select>
                          <div v-if="$v.applicationTier.$error" class="error">Please select an option</div>
                        </div>
                      </div>

                      <div class="form-group col-12">
                        <button class="btn btn-success px-4" @click.prevent="saveForm">Save</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import client from '@/api/client';

import { required, numeric, maxLength, email, } from 'vuelidate/lib/validators';


export default {
  data(){
    return {
        darkMode: false,
        links: [
        {
            to: 'profile',
            title: 'Profile',
            icon: 'mdi mdi-bullseye',
            text: 'Account Overview',
        },
        {
            to: 'settings-account',
            title: 'SettingsAccount',
            icon: 'mdi mdi-bank', 
            text: 'Linked Bank Accounts',
        },
        {
            to: 'settings-crypto',
            title: 'SettingsCrypto',
            icon: 'mdi mdi-bank',
            text: 'Linked Crypto Wallets',
        },
        {
            to: 'settings',
            title: 'VipApplication',
            icon: 'mdi mdi-lock',
            text: 'VIP Application',
        },
        ],
        first: '',
        last: '',
        mi: '',
        addr1: '',
        addr2: '',
        city: '',
        state: '',
        zip: '',
        since: '' /* moment(since).toISOString() */,
        phoneType: '',
        phone: '',
        email: '',
        ssn1: '',
        ssn2: '',
        ssn3: '',
        citizenship: '',
        dob: '' /* moment(dob).toISOString() */,
        idType: '',
        idNumber: '',
        ssn: this.ssn1 + this.ssn2 + this.ssn3,
        issued: '',
        state2: '',
        expires: '' /* moment(expires).toISOString() */,
        gender: '' /* genderValue.toUpperCase() */,
        usResident: '',
        employmentStatus: '',
        employer: '',
        employerAddr1: '',
        employerAddr2: '',
        employerCity: '',
        employerState: '',
        employerZip: '',
        jobTitle: '',
        income: '',
        maidenName: '',
        firstSchool: '',
        useageDescription: '',
        intendUseDetail: '',
        applicationTier: '',
        txForOthers: '',
        hasBankAccount: '',
        fundSource: '',
        fundSourceDetail: '',
    }
  },
  // Form data
  // },
  computed: {
    name() {
      const data = client.token.id.data;
      return `${data.user_fname} ${data.user_lname}`;
    },

  },
  mounted() {
    api.user.getApprovedInfo().then(data => {
      // console.log("approved documents:", data)
    }, err => {
      // console.error("error getting approved documents ===>", err)
    })
    api.user.getPendingInfo().then(data => {
      // console.log("pending documents:", data)
    }, err => {
      // console.error("error getting pending documents ===>", err)
    })
    this.getAccounts();
  },
  methods: {
    formatDate(dateVal){
      const [year, month, day] = dateVal.split('-');
      const date = new Date(year, month, day);
      return date.toJSON();
    },
    getAccounts() {
      this.loading = true;
      api.account.read().then((res) => {
        this.loading = false;
        this.accounts = res;
      }).catch((err) => {
        this.$toast.error('Something went wrong, please refresh');
        this.loading = false;
      });
    },
    deleteAccount(account) {
      this.$toast.clear();
      const canDelete = confirm('Are you sure you want to delete this account');
      if (canDelete) {
        this.loading = true;
        api.account.delete(account.id).then((res) => {
          this.loading = false;
          this.$toast.success('Account deleted successfully');
          this.getAccounts();
        }).catch((err) => {
          this.loading = false;
          this.$toast.error(err);
        });
      }
    },
    async saveForm(){
      //  collate the data

      let data = {
          "type": "OTC_APPLICATION_VIP",
          "name": {
              "first": this.first,
              "last": this.last, 
              "mi": this.mi
          },
          "address":{ 
              "addr1": this.addr1,
              "addr2": this.addr2,
              "city": this.city,
              "state": this.state,
              "zip": this.zip,
              "since": ""
          },
          "contact":{ 
              "phoneType": this.phoneType,
              "phone": this.phone,
              "email": this.email
          },
          "identity":{
              "citizenship": this.citizenship,
              "dob": this.formatDate(this.dob),
              "idType": this.idType,
              "state": this.state,
              "idNumber": this.idNumber,
              "ssn":this.ssn1 + this.ssn2 + this.ssn3,
              "issued": this.formatDate(this.issued),
              "expires": this.formatDate(this.expires),
              "gender": this.gender.toUpperCase(),
              "usResident": this.usResident == 'Yes' ? true : false
          },
          "employment":{
              "employmentStatus": this.employmentStatus,
              "address":{
                  "addr1": this.employerAddr1,
                  "addr2": this.employerAddr2
              },
              "employer": this.employer,
              "jobTitle": this.jobTitle,
              "income": this.income
          },
          "security":{
              "maidenName": this.maidenName,
              "firstSchool": this.firstSchool
          },"additional":{
              "applicationTier": this.applicationTier,
              "useageDescription": this.intendUseDetail,
              "txForOthers": this.txForOthers == 'Yes' ? true : false,
              "hasBankAccount": this.hasBankAccount == 'Yes' ? true : false,
              "fundSource": this.fundSource
          },
          "previousAddress":{}
      }

      // console.log("heySubmit ===> ", data);

      this.$v.$touch();
			if(this.$v.$invalid){
        // console.log("There is an error => ", this.$v)
        alert("Please make sure all required fields are filed before submitting");
        // The code below is for debgging errors
        let keys = Object.keys(this.$v);
        // console.log("Keys are ", keys);
        keys.forEach(key => {
          if(this.$v[key] && this.$v[key].$anyError){
            // console.log("Error on key => ", key);
          }
        });
      }else{
        try{
          await api.user.addInfo(data);
        }catch(err){
          // console.error("error adding info ===>", err);
        }
        this.$router.push({ path: 'dashboard' });
        // console.log("No more errors!!!")
      }
    }
  },
  validations: {
    first: { required },
    last: { required },
    addr1: { required },
    city: { required },
    state: { required },
    zip: { required },
    since: { required } /* moment(since).toISOString() */,
    phoneType: { required },
    phone: { required },
    email: { required, email },
    ssn1: { 
      required,
      minLength: maxLength(3)
    },
    ssn2: { 
      required,
      minLength: maxLength(2)
    },
    ssn3: { 
      required,
      minLength: maxLength(4)
    },
    citizenship: { required },
    dob: { required } /* moment(dob).toISOString() */,
    idType: { required },
    idNumber: { required },
    issued: { required },
    state2: { required },
    expires: { required } /* moment(expires).toISOString() */,
    gender: { required } /* genderValue.toUpperCase() */,
    usResident: { required },
    employmentStatus: { required },
    employer: { required },
    employerAddr1: { required },
    employerCity: { required },
    employerState: { required },
    employerZip: { required },
    jobTitle: { required },
    income: { required },
    maidenName: { required },
    firstSchool: { required },
    intendUseDetail: { required },
    applicationTier: { required },
    txForOthers: { required },
    hasBankAccount: { required },
    fundSource: { required },
  }
};
</script>

<style lang="scss" scoped>
.card-profile__info h5 {
  display: inline-block;
}

.error{
  color: red;
}

.sub-menu {
  ul {
    @include screen('phone-land') {
      display: inline-block !important;
    }

    li {
      a {
        padding: 0px 12px;
        display: flex;
        align-items: center;
        color: var(--body-color);

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: var(--primary);
          background-color: transparent;
        }
      }

      &.active {
        a {
          color: var(--primary);
        }
      }

      i {
        font-weight: bold;
        margin-right: 7px;
        font-size: 20px;
      }

      span {
        font-weight: 400;
      }
    }
  }
}

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid var(--border-color);
  padding-top: 30px;
}

.phone_verified {
  display: flex;
  align-items: center;

  h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: var(--body-color);

    span {
      // background: var(--primary);
      color: var(--primary);
      padding: 9px 10px;
      border-radius: 50px;
      margin-right: 7px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid var(--border-color);
      height: 40px;
      width: 40px;
      display: inline-block;
    }
  }

  .verified,
  .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;

    span {
      background: var(--success);
      color: var(--white);
      padding: 10px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .not-verify {
    span {
      background: var(--danger);
    }
  }
}

// Security
.id_card {
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 20px 0px;
}

table {
  color: var(--text);
}
</style>
